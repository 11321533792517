import React, { useContext, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styles from './Profile.module.css';
import { FaAngleDown } from 'react-icons/fa6';
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HashLoader from 'react-spinners/HashLoader';
import logoutSound from '../assets/mixkit-select-click-1109.wav';

//---------------- Address Form Component--------------------
const AddressForm = ({ address, handleChange, handleSubmit, isEdit, errorMessage }) => (
  <div className={styles.productForm}>
    <h2>{isEdit ? 'Edit Address' : 'Add New Address'}</h2>
    <form onSubmit={handleSubmit}>
      {['Full Name', 'Full Address', 'Vill/City', 'Dist', 'State', 'Pin', 'Phone'].map((field) => (
        <div key={field} className={styles.formGroup}>
          <label>{field}:</label>
          {field === 'Add' ? (
            <textarea
              name={field}
              value={address[field]}
              onChange={handleChange}
              required
            />
          ) : (
            <input
              type="text"
              name={field}
              value={address[field]}
              onChange={handleChange}
              required
            />
          )}
        </div>
      ))}
      <button type="submit" className={styles.submitButton}>
        {isEdit ? 'Edit' : 'Submit'}
      </button>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </form>
  </div>
);

//----------- Order List Component with useMemo for better performance on large data-----------
const OrderList = ({ orderList }) => {
  const orderListMemo = useMemo(() => {
    return orderList.map(({ imgSrc, price, qty, title, _id }) => (
      <div key={_id} className={styles.productDiv}>
        <img className={styles.productImg} src={imgSrc} alt="product" />
        <div className={styles.productDetails}>
          <p>{title}</p>
          <p className={styles.quantity}>Quantity: {qty}</p>
          <p className={styles.price}>Price: {price}</p>
          <p className={styles.deliveryInfo}>Order will be delivered in 7 days</p>
        </div>
      </div>
    ));
  }, [orderList]);

  return <div className={styles.allOrder}>{orderListMemo}</div>;
};


const Profile = () => {
  // console.log('Profile.js');
  
  const [loading, setLoading] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');
  const [activeAdd, setActiveAdd] = useState(false);
  const [activeOrder, setActiveOrder] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [address, setAddress] = useState({
    FullName: '',
    Add: '',
    VillorCity: '',
    Dist: '',
    State: '',
    Pin: '',
    Phone: ''
  });

  const audioRef = useRef(null);
  const auth = useContext(productContext);
  const url = auth.url;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAddress = async () => {
      await auth.fetchUserAddress();
      setLoading(false);
    };
    fetchAddress();
  }, []);

  useEffect(() => {
    setUserName(localStorage.getItem('userName') || '');
    setUserEmail(localStorage.getItem('userEmail') || '');
  }, []);

  //----------- Handle input changes in address form--------
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  }, []);

  //----------- Logout function using useCallback----------
  const logOut = useCallback(() => {
    setLoggingOut(true); 
    audioRef.current.play();
    setLogoutMessage('You have logged out.'); 
    setTimeout(() => {
      auth.setAuthinticated('');
      ['token', 'userName', 'userEmail', 'userId'].forEach((item) =>
        localStorage.removeItem(item)
      );
      window.scrollTo(0, 0);
      navigate('/login');
      auth.setAddress('');
      setLoggingOut(false); 
    }, 2000); 
  }, [auth, navigate]);
  const toggleAddress = useCallback(() => setActiveAdd(!activeAdd), [activeAdd]);

  const toggleOrder = useCallback(async () => {
    setActiveOrder(!activeOrder);
    if (!activeOrder) {
      try {
        setLoading(true);
        const { data } = await axios.get(`${url}/api/payment/getOrderById`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Auth': auth.authinticated
          }
        });
        setOrderList(data.flatMap((order) => order.orderItems));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    }
  }, [activeOrder, auth.authinticated, url]);

  // ------------Handle address form submission----------
  const handleAddressSubmit = useCallback(
    async (e) => {
      window.scrollTo(0, 0);
      e.preventDefault();
      try {
        const endpoint = auth.address?._id
          ? `${url}/api/address/updateAddress/${auth.address._id}`
          : `${url}/api/address/addAddress`;
        const method = auth.address ? 'put' : 'post';

        const { data } = await axios[method](endpoint, address, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Auth': auth.authinticated
          }
        });
        setErrorMessage(data.message);
        window.location.reload();
      } catch (error) {
        console.error('Error submitting Address:', error.message);
      }
    },
    [address, auth.address, auth.authinticated, url]
  );

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <HashLoader color={'#009578'} loading={loading} size={58} />
        </div>
      ) : (
        <div>
          <div className={styles.profileDetails}>
            <h2>Welcome {userName}</h2>
            <h3>Email: {userEmail}</h3>
            <button onClick={logOut} className={`${styles.logOutBtn} ${loggingOut ? styles.loggingOut : ''}`}>
              {loggingOut ? 'Logging out...' : 'Log Out'}
            </button>
            {logoutMessage && <p className={styles.logoutMessage}>{logoutMessage}</p>} 
          </div>

          <div onClick={toggleAddress} className={styles.address}>
            <h2>Address</h2>
            <FaAngleDown />
          </div>

          {activeAdd && (
            <>
              {auth.address && (
                <div className={styles.addressDiv}>
                  <div className={styles.fullAddress}>
                    <h3 className={styles.addressh3}>Address : </h3><p className={styles.addressp}>{auth.address.Add}</p>
                  </div>
                  <div className={styles.sepAddressDiv}>
                    {['FullName', 'Vill/City', 'Dist', 'State', 'Pin', 'Phone'].map((field) => (
                      <div key={field} className={styles.sepAddress}>
                        <h3>{field}: </h3>
                        <p>{auth.address[field]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <AddressForm
                address={address}
                handleChange={handleChange}
                handleSubmit={handleAddressSubmit}
                isEdit={!!auth.address}
                errorMessage={errorMessage}
              />
            </>
          )}

          <div onClick={toggleOrder} className={styles.orders}>
            <h2>Orders</h2>
            <FaAngleDown />
          </div>

          {activeOrder && <OrderList orderList={orderList} />}

          <div className={styles.editProfile}>
            <h2>Edit Profile</h2>
            <FaAngleDown />
          </div>
        </div>
      )}
      <audio ref={audioRef} src={logoutSound} preload="auto" />
    </div>
  );
};

export default Profile;
