import React, { useContext, useState, useMemo, useCallback } from 'react';
import styles from "./SearchSari.module.css";
import Allsari from '../Body/Allsari';
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';

const SearchSari = () => {
    // console.log("SearchSari.js");
    
    const navigate = useNavigate();
    const { products } = useContext(productContext);
    const [date, setDate] = useState("");
    const [isActive, setIsActive] = useState(false);

    // useEffect(() => {
    //     fetchProducts();  
    // }, [fetchProducts]);

    const sariData = useMemo(() => {
        return products.filter(product => product.date === date);
    }, [products, date]);

    const handleFocus = useCallback(() => setIsActive(true), []);
    const handleBlur = useCallback(() => setIsActive(false), []);
    const handleDateChange = useCallback((e) => setDate(e.target.value), []);

    const toDetails = useCallback((id) => {
        navigate(`/productDetails/${id}`);
    }, [navigate]);

    return (
        <div className={styles.outerDiv}>
            <div className={styles.formDiv}>
                <label htmlFor="Date" className={styles.date}>
                    Search Sari By Date
                </label>
                <input
                    className={isActive ? styles.activeInput : styles.input}
                    type="date"
                    value={date}
                    onChange={handleDateChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
            {sariData.length > 0 ? (
                <div className={styles.container}>
                    {sariData.map((elem) => {
                        const { imgSrc, Price, title, _id } = elem;
                        return (
                            <div
                                onClick={() => toDetails(_id)}
                                key={_id}
                                className={styles.maindiv}
                            >
                                <div className={styles.image}>
                                    <img src={imgSrc} alt={title} />
                                </div>
                                <div className={styles.details}>
                                    {title} - {Price}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Allsari />
            )}
        </div>
    );
};

export default SearchSari;
