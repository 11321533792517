import React from 'react';
import styles from "./Catagory.module.css";


const Catagory = () => {

    return (
        <div>
            <div className={styles.bigbox}>
                <div className={styles.Heading} >
                    <div className={styles.mainHeading}>
                        <h1 className={styles.title}>Exclusive Deals</h1>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/products/1_495307f9-c8b9-4884-a302-642b8e97d5ef_360x.jpg?v=1641894283" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>LILEN SARI</p>
                            </div>
                        </div>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/products/final-1_990c5285-b334-4663-bf27-43f62dd4ad9d_360x.jpg?v=1641383233" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>BANARASI SARI</p>
                            </div>
                        </div>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/products/final-1_f50ed0e7-19f1-41c3-a727-867ebb6be5c1_360x.jpg?v=1675242843" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>READY MADE BLOUSE</p>
                            </div>
                        </div>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/products/final-1_46a8ff1d-5381-4615-9196-0a65129374f3_360x.jpg?v=1641383229" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>KANJIVARAM SILK</p>
                            </div>
                        </div>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/files/final-1_72300703-7c97-473f-89db-ffdd1a9a6265_360x.jpg?v=1688291824" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>HANDLOOM SILK</p>
                            </div>
                        </div>
                        <div className={styles.catagories}>
                            <img className={styles.catagories} src="https://www.bharatsthali.com/cdn/shop/files/final-2_24356b26-fac6-4c3d-b06b-d087df00bd73_360x.jpg?v=1708365415" alt="" />
                            <div className={styles.cata}>
                                <p className={styles.cata2}>BRIDAL SILK SARI</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div >
    );
}

export default Catagory;
