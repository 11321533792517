import React, { useContext, useState, useEffect, useCallback } from 'react';
import styles from './Header.module.css';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiShoppingBag } from 'react-icons/fi';
import { RxAvatar } from 'react-icons/rx';
import { HiOutlineMenuAlt1, HiOutlineX } from 'react-icons/hi';
import { IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';

const Header = () => {
  const { authinticated } = useContext(productContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(prevState => !prevState);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  const handleNavigation = useCallback(
    (path) => {
      window.scrollTo(0, 0);
      navigate(path);
      closeMobileMenu();
    },
    [navigate, closeMobileMenu]
  );

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isMobileMenuOpen]);

  const socialLinks = [
    { href: 'https://www.facebook.com/Darshpage', icon: <FaFacebook />, tooltip: 'Our Facebook' },
    { href: 'https://www.instagram.com/darsh_bysathi/', icon: <FaInstagram />, tooltip: 'Our Instagram' },
    { href: 'https://wa.link/wdfj7f', icon: <FaWhatsapp />, tooltip: 'Our Whatsapp' },
  ];

  return (
    <header className={styles.header}>
      <div className={styles.upHeader}>
        <nav className={styles.inUpheader}>
          <ul className={`${styles.listUpheaderLeft} ${isMobileMenuOpen ? styles.open : ''}`}>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/')}>Home</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/profile')}>My Account</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/HotSells')}>Hot Sells</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/NewProduct')}>New Arrivals</button></li>
            <li><button className={styles.navButton} onClick={() => handleNavigation('/blogpage')}>Blog</button></li>
          </ul>
           <div className={`${styles.listUpheaderRight} ${isMobileMenuOpen ? styles.mobile : ''}`}>
              {socialLinks.map(({ href, icon, tooltip }, index) => (
                <div className={styles.iconWrapper} key={index}>
                  <a href={href} target="_blank" rel="noreferrer">
                    {icon}
                  </a>
                  <span className={styles.tooltip}>{tooltip}</span>
                </div>
              ))}
            </div>
        </nav>
      </div>

      <div className={styles.stick}>
        <div className={styles.mainHeader}>
          <div className={styles.inMainheader}>
            <div className={styles.logoDiv} onClick={() => handleNavigation('/')}>
              <img
                className={styles.logo}
                src="https://firebasestorage.googleapis.com/v0/b/login-page-98c52.appspot.com/o/Darsh%20web%2Fdarsh%20img.png?alt=media&token=e5fe3fe4-cd99-4168-aba6-b5b2c8060ce3"
                alt="Darsh Logo"
              />
            </div>

            <div className={styles.profileAndCart}>
              <div className={styles.iconWrapper}>
                <IoSearch className={`${styles.search} ${styles.clickableIcon}`}  onClick={() => handleNavigation('/SearchSari')} />
                <span className={styles.tooltip}>Search</span>
              </div>

              <FiShoppingBag className={styles.clickableIcon} onClick={() => handleNavigation('/cart')} />

              <div className={styles.iconWrapper}>
                <RxAvatar className={styles.clickableIcon} onClick={() => handleNavigation(authinticated ? '/profile' : '/login')} />
                <span className={styles.tooltip}>Your Account</span>
              </div>

              {isMobileMenuOpen ? (
                <HiOutlineX className={styles.menuicon} onClick={toggleMobileMenu} aria-label="Close Menu" />
              ) : (
                <HiOutlineMenuAlt1 className={styles.menuicon} onClick={toggleMobileMenu} aria-label="Open Menu" />
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <div className={styles.menuOverlay} onClick={toggleMobileMenu} />}
    </header>
  );
};

export default Header;
