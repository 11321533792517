import React from "react";
import styles from './Footer.module.css';
import { FaShippingFast, FaHeadset, FaTape, FaFacebook, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { GiReturnArrow } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    // console.log("Footer.js");
    
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };

    const footerLinks = [
        {
            title: "ABOUT DARSH",
            links: [
                { label: "About Us", path: "/aboutus" },
                { label: "Payment Option", path: "/PaymentOptions" },
                { label: "Blog", path: "/blogpage" },
            ],
        },
        {
            title: "CONTACT US",
            links: [
                { label: "9907804710", path: "https://wa.link/wdfj7f", isExternal: true },
                { label: "darshsathi@gmail.com", path: "mailto:darshsathi@gmail.com", isExternal: true },
                { label: "Help Center", path: "/contactus" },
            ],
        },
        {
            title: "POLICIES",
            links: [
                { label: "Privacy Policy", path: "/PrivacyPolicy" },
                { label: "Shipping and Delivery", path: "/ShippingAndDelivery" },
                { label: "Return & Exchange", path: "/CancellationandRefund" },
                { label: "Terms & Conditions", path: "/terms-and-conditions" },
                { label: "Disclaimer", path: "/disclaimer" },
            ],
        },
    ];

    const socialLinks = [
        { icon: FaFacebook, path: "https://www.facebook.com/Darshpage" },
        { icon: FaWhatsapp, path: "https://wa.link/wdfj7f" },
        { icon: FaInstagram, path: "https://www.instagram.com/darsh_bysathi" },
    ];

    const serviceItems = [
        { icon: FaHeadset, label: "24x7 Customer Support" },
        { icon: FaShippingFast, label: "Fast Delivery*" },
        { icon: GiReturnArrow, label: "Easy Returns" },
        { icon: FaTape, label: "Custom Fitting" },
    ];

    return (
        <div className={styles.footer}>
            <div className={styles.sb__footer}>
                <div className={styles.sb__footerLinks}>
                    {footerLinks.map((section, index) => (
                        <div key={index} className={styles.sb__footerLinksDiv}>
                            <h4>{section.title}</h4>
                            {section.links.map((link, i) =>
                                link.isExternal ? (
                                    <a key={i} href={link.path} target="_blank" rel="noopener noreferrer">
                                        <p>{link.label}</p>
                                    </a>
                                ) : (
                                    <p key={i} onClick={() => handleNavigation(link.path)}>
                                        {link.label}
                                    </p>
                                )
                            )}
                        </div>
                    ))}

                    <div className={styles.sb__footerLinksDiv}>
                        <h4>FOLLOW US</h4>
                        <div className={styles.socialmedia}>
                            {socialLinks.map((social, i) => (
                                <a key={i} href={social.path} target="_blank" rel="noreferrer">
                                    <social.icon className={styles.socialIcon} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.footerTop}>
                    {serviceItems.map((item, i) => (
                        <div key={i} className={styles.footerItem}>
                            <item.icon className={styles.footerIcon} />
                            <p>{item.label.split("<br />").join("\n")}</p>
                        </div>
                    ))}
                </div>

                <div className={styles.footerShip}>
                    <h5>We Ship Across India</h5>
                    <p>West Bengal, Bihar, Punjab, Rajasthan, Odisha, Kerala etc.</p>
                </div>

                <div className={styles.sb__footerBelow}>
                    <div className={styles.sb__footerCopyright}>
                        <p>@{new Date().getFullYear()} CoderDeba. All rights reserved.</p>
                    </div>
                    <div className={styles.sb__footerBelowLinks}>
                        <p onClick={() => handleNavigation("/terms-and-conditions")}>Terms & Conditions</p>
                        <p onClick={() => handleNavigation("/PrivacyPolicy")}>Privacy Policy</p>
                        <p onClick={() => handleNavigation("/disclaimer")}>Disclaimer</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
