import React, { useContext, useEffect, useState } from 'react';
import styles from "./NewProduct.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import HashLoader from "react-spinners/HashLoader";

const NewProduct = () => {
    const allData = useContext(productContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            await allData.fetchProducts();
            setLoading(false);
        };
        fetchProducts();
    }, []);

    const newProducts = allData.products || []; // Ensure products is an array

    // Subtract 5 days (in milliseconds) from the current date
    const currentDate = Date.now() - (5 * 24 * 60 * 60 * 1000);

    // Filter products that have a date earlier than or equal to the current date
    const newSari = newProducts.filter(product => {
        const productDate = new Date(product.date).getTime(); // Convert product date to timestamp
        return productDate >= currentDate; // Compare the timestamps
    });


    const navigate = useNavigate();

    const toDetails = (id) => {
        navigate(`/productDetails/${id}`);
    };

    return (
        <div>
            {loading ? (
                <div className={styles.loaderContainer}>
                    <HashLoader color={'#00796b'} loading={loading} size={58} />
                </div>
            ) : (
                <div>
                    <h1 className={styles.newProductHeading}>New Arrivals</h1>
                    <h3 className={styles.newProductSubheading}>Explore our latest collection</h3>
                    <div className={styles.container}>
                        {newSari.length > 0 ? (
                            newSari.map((elem) => {
                                const { imgSrc, price, title, _id } = elem;
                                return (
                                    <div onClick={() => toDetails(_id)} key={_id} className={styles.maindiv}>
                                        <div className={styles.imageContainer}>
                                            <img src={imgSrc} alt={title} className={styles.image} />
                                        </div>
                                        <div className={styles.details}>
                                            <h3 className={styles.title}>{title}</h3>
                                            <p className={styles.price}>₹{price}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No new products available at the moment.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewProduct;
