import React, { useContext, useEffect, useState,useRef } from 'react';
import styles from "./Allsari.module.css";
import { useNavigate } from 'react-router-dom';
import productContext from '../Context/ProductContext/ProductContext';
import HashLoader from "react-spinners/HashLoader";

const Allsari = () => {
    // console.log("Allsari.js");
    
    const context = useContext(productContext);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Initially set loading to true
    const navigate = useNavigate();
    const productRefs = useRef([]);

    const loadingFun = async () => {
        if (products.length === 0) {
            // Only fetch if products are not available
            const response = await context.fetchProducts();
            // console.log(response.data.product);
            setProducts(response.data.product); // Correctly set the fetched products
            setLoading(false); // After fetching, stop loading
        }
    }

    useEffect(() => {
        loadingFun();
    }, []); 

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,  
        };
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                    observer.unobserve(entry.target); 
                }
            });
        }, observerOptions);
    
        const currentRefs = productRefs.current;
    
        currentRefs.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });
    
        return () => {
            currentRefs.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, [products]); 

    const toDetails = (id) => {
        window.scrollTo(0, 0);
        navigate(`/productDetails/${id}`);
    };

    // Loading state
    return (
        <div>
            {loading ? (
                <div className={styles.loaderContainer}>
                    <HashLoader color={'#009578'} loading={loading} size={58} />
                </div>
            ) : (
                <div>
                    <h1 className={styles.allSariHeading}>TRENDY COLLECTION</h1>

                    <div className={styles.container}>
                        {products.map(({ imgSrc, price, title, _id }, index) => (
                            <div
                                key={_id}
                                onClick={() => toDetails(_id)}
                                className={styles.maindiv}
                                ref={(el) => (productRefs.current[index] = el)} 
                            >
                                <div className={styles.image}>
                                    <img src={imgSrc} alt={title} />
                                </div>
                                <div className={styles.details}>
                                    {title}<br />
                                    <p>₹{price}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Allsari;
