import React from 'react'
import FirstPage from './FirstPage'
import Catagory from './Catagory'
import Allsari from './Allsari'
const Body = () => {
    return (
        <>
            <FirstPage />
            <Catagory />
            <Allsari />
        </>
    )
}

export default Body
