import React, { useState, useContext, useCallback, useRef } from 'react';
import styles from './Register.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import productContext from '../Context/ProductContext/ProductContext';
import clickSound from '../assets/message-alert-190042.mp3'; 

const Login = () => {
    const { url, setAuthinticated, setUser } = useContext(productContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const audioRef = useRef(null);

    const handleChange = useCallback((e) => {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        // Play the click sound
        audioRef.current.play();

        try {
            const { data } = await axios.post(`${url}/api/user/login`, formData);

            if (data.success) {
                const { token, user } = data;

                setAuthinticated(token);
                setUser(user);

                localStorage.setItem('token', token);
                localStorage.setItem('userName', user.name);
                localStorage.setItem('userEmail', user.email);
                localStorage.setItem('userId', user._id);

                toast.success(
                    <div className={styles.toastContent}>
                        Logged in successfully! Redirecting to profile...
                    </div>,
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        transition: Flip,
                        className: styles.successToast
                    }
                );

                setTimeout(() => {
                    setLoading(false);
                    window.scrollTo(0, 0);
                    navigate('/profile');
                }, 4000);
            } else {
                setError(data.message);
                setLoading(false);
            }
        } catch (err) {
            console.error('There was an error logging in!', err);
            setError('An error occurred. Please try again later.');
            setLoading(false);
        }
    }, [formData, url, setAuthinticated, setUser, navigate]);

    return (
        <div className={styles.authContainer}>
            <ToastContainer />
            <form className={styles.authForm} onSubmit={handleSubmit}>
                <h2 className={styles.heading}>Log In</h2>

                <label className={styles.label} htmlFor="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    className={styles.inputField}
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                />

                <label className={styles.label} htmlFor="password">Password:</label>
                <input
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    required
                    className={styles.inputField}
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                />

                <button
                    type="submit"
                    className={`${styles.submit} ${loading ? styles.disabled : ''}`}
                    disabled={loading}
                >
                    {loading ? 
                        <div>
                            <span className={styles.res}>Logged in ...</span>
                            <span className={styles.spinner}></span>
                        </div>
                     : 'SUBMIT'}
                </button>

                <p className={styles.instruction}>
                    Not a user? <span className={styles.link} onClick={() => navigate('/register')}>Sign Up</span>
                </p>

                {error && (
                    <div className={styles.errorDiv}>
                        <p className={styles.error}>{error}</p>
                    </div>
                )}
            </form>
            <audio ref={audioRef} src={clickSound} preload="auto" />
        </div>
    );
};

export default Login;
