import React, { useState } from 'react';
import styles from './ContactUs.module.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className={`${styles.contactUs} ${styles.fadeInPage}`}>
      <div className={styles.contactUsForm}>
        <div className={`${styles.contactContainer} ${styles.fadeIn}`}>
          <h1>Help Center</h1>
          <p>If you have any questions or concerns, feel free to reach out to us.</p>
          <form onSubmit={handleSubmit} className={styles.contactForm}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your name"
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your email"
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your message..."
            ></textarea>

            <button type="submit">Send</button>
          </form>
        </div>
      </div>

      <div className={`${styles.contactUsInfo} ${styles.fadeIn}`}>
        <div className={styles.contactTitle}>
          <h3>WE ARE HERE - YOU CAN KEEP IN TOUCH WITH US ANYTIME</h3>
        </div>
        <div className={styles.contactDetails}>
          <h4>Contact Us:</h4>
          <ul>
            <li>Phone: +91 1234567890</li>
            <li>Email: darsh@gmail.com</li>
            <li>Address : Patrasayer, Bankura Patrasayer, West Bengal 722206</li>
          </ul>
        </div>
        <div className={styles.colorfulSaree}>
          <h3>COLORFUL SAREES ONLINE ON DARSH</h3>
          <p>
            The traditional drape gets a digital makeover with us! Bringing you an array of much-adored desi sarees at your fingertips, we are here to make your every moment a special and colourful one.
          </p>
        </div>
        <div className={styles.latestTrend}>
          <h3>LATEST TREND IN COLORFUL SAREES</h3>
          <p>
            Available in silk, cotton, linen, jute, chiffon georgette and blends, the sarees are perfect to bring some calm and colour to your day-to-day life. Perfect for work wear, party, and special occasions such as weddings and puja, these sarees are comfortable to wear and can easily be maintained for life with little care.
            Whether you are looking for Kerala silk sarees with solid colours or want dual-toned Kota Doria cotton sarees to wear to work, here is everything to make your day look cheerful and colourful as it gets. Complement your traditional look with minimal makeup, a pair of matching heels and a beautiful hairdo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
