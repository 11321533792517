import React, { useState, useEffect, useRef } from 'react';
import { SlArrowDown } from 'react-icons/sl';
import styles from './BlogPage.module.css';

const BlogVideoPost = ({ videoUrl, title, description, date }) => {
    return (
        <div className={styles.blogVideo}>
            <div className={styles.videoContainer}>
                <iframe
                    width="560"
                    height="315"
                    src={videoUrl}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </div>
            <div className={styles.content}>
                <h2 className={styles.postTitle}>{title}</h2>
                <span>{description}</span>
                <p className={styles.postDescription}>{date}</p>
            </div>
        </div>
    );
};

const BlogPage = () => {
    const [selectedOption, setSelectedOption] = useState('ALL');
    const [isOpen, setIsOpen] = useState(false);
    const videoRef = useRef(null);

    const blogPosts = [
        {
            videoUrl: 'https://www.youtube.com/embed/ybnn60xYKgk?si=FI6VcOuf5kfflBc9&amp;start=1',
            title: 'Kanthastitch chiffon organza Puja collection',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 23, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/1FMGO-e2zS0?si=RYv_on-CqL_NHpS9',
            title: 'Kanthastitch chiffon organza Puja collection',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 23, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/QcdelIVhlzw?si=FmA1sNwhST4lm4Gb',
            title: 'Kanthastitch chiffon organza Puja collection',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 21, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/q0n--kCSXQk?si=FymA7TqoyGIfzrAz',
            title: 'Kanthastitch chiffon organza',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 17, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/4uZbzoOD8kI?si=HjBeWaO-JsVCw28x',
            title: 'Kanthastitch chiffon organza',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 10, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/hGla63qO6Ck?si=qzsoJYkG-iafHXGr',
            title: 'Kanthastitch chiffon organza',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 7, 2024',
        },
        {
            videoUrl: 'https://www.youtube.com/embed/QbNw9b2j1xE?si=m0nbn3Xc9ZYu6Bj7',
            title: 'Kanthastitch chiffon organza',
            description: 'Puja collection | wp us: 8927370455/6295061098 | Semi kalakhetra',
            date: 'Aug 5, 2024',
        },
    ];

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        console.log(`Selected Option: ${option}`);
    };

    useEffect(() => {
        const video = videoRef.current;
        const isVideoPlaying = localStorage.getItem('isVideoPlaying') === 'true';
        if (isVideoPlaying && video) {
            video.play();
        }

        return () => {
            if (video) {
                localStorage.setItem('isVideoPlaying', video.paused ? 'false' : 'true');
            }
        };
    }, []);

    return (
        <>
            <div className={styles.blogMain}>
                <div className={styles.blogTitle}>
                    <h1>BLOG</h1>
                </div>
                <div className={styles.blogMenu}>
                    <div className={styles.selectBtn} onClick={() => setIsOpen(!isOpen)}>
                        <span className={styles.sBtnTxt}>{selectedOption}</span>
                        <SlArrowDown />
                    </div>
                    {isOpen && (
                        <ul className={styles.options}>
                            {['ALL', 'Category_Culture', 'Category_Infographics', 'Category_Life Style', 'Category_Villages'].map((option, index) => (
                                <li key={index} className={styles.option} onClick={() => handleOptionClick(option)}>
                                    <span className={styles.optionText}>{option}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            <div className={styles.blogPage}>
                <div className={styles.blogPosts}>
                    {blogPosts.map((post, index) => (
                        <BlogVideoPost
                            key={index}
                            videoUrl={post.videoUrl}
                            title={post.title}
                            description={post.description}
                            date={post.date}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default BlogPage;
