import React from 'react'
import styles from "./FirstPage.module.css"
import { useNavigate } from 'react-router-dom'

const FirstPage = () => {
    const navigate = useNavigate();

    const goToProduct = () => {
        window.scrollTo(0, 0);
        navigate("/NewProduct")
    }
    const goToHotProduct = () => {
        window.scrollTo(0, 0);
        navigate("/HotSells")
    }

    return (
        <>
            <div>
                <div className={styles.homeImg} >
                    <img src="./IMG/karwa_chauth_sarees_1400x.jpg" alt="" className={styles.imgFront} />
                    <div className={styles.upImgDiv}>
                        <div className={styles.firstUpImgDiv}>
                            <p className={styles.para}>YOUR LOVE FOR SAREES</p>
                        </div>
                        <div className={styles.secondUpImgDiv}>
                            <p className={styles.para2}>BEAUTIFULLY CRAFTED<br />
                                HANDLOOM SAREES</p>
                        </div>
                        <div className={styles.thirdUpImgDiv}>
                            <p className={styles.para}>By Indian Artisans</p>
                        </div>
                        <div className={styles.fourthUpImgDiv}>
                            <div className={styles.shopbtn}onClick={goToProduct}>
                                <p className={styles.para3}  >NEW ARRIVALS</p>
                            </div>
                            <div className={styles.shopbtn} onClick={goToHotProduct}>
                                <p className={styles.para3}>HOT SELLS</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FirstPage
