import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductContext from './ProductContext';

const ProductState = (props) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [products, setProducts] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [user, setUser] = useState({});
    const [address, setAddress] = useState({});
    // const url = "http://localhost:2000";
    // const url = "https://darsh-backend.onrender.com";
    const url = "https://monkfish-app-gky78.ondigitalocean.app";


    // function to fetch all product data
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${url}/api/product/getallproduct`);
            // console.log("fetched from backend", response.data.product);
            setProducts(response.data.product);
            return response;
        } catch (error) {
            console.log(error.message);
        }
    };
    // Fetch user Address
    const fetchUserAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            // http://localhost:2000/api/address/getAddressById
            const Address = await axios.get(`${url}/api/address/getAddressById`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': token
                }
            });
            // console.log(Address);
            setAddress(Address.data.address);
        } catch (error) {
            console.log(error.message);
        }
    }
    // Fetch userCart Data
    const fetchCartData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/cart/usercart`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': token
                }
            });
            const fetchedCartData = response.data.cart.items;
            setCartData(fetchedCartData);

            // Calculate total price immediately after setting cartData
            const totalPrice = fetchedCartData.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.price;
            }, 0);
            setTotalPrice(totalPrice);
        } catch (error) {
            console.log(error.message);
        }
    };
    // check Authinticated 
    const [authinticated, setAuthinticated] = useState()
    // console.log(address)
    useEffect(() => {
        fetchProducts();
    }, [])

    return (
        <ProductContext.Provider value={{ products, setAuthinticated, authinticated, cartData, fetchProducts, fetchCartData, totalPrice, user, setUser, fetchUserAddress, address, setAddress, url }}>
            {props.children}
        </ProductContext.Provider>
    );
};

export default ProductState;
