import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import styles from './Cart.module.css';
import productContext from '../Context/ProductContext/ProductContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HashLoader from "react-spinners/HashLoader";
import useSound from 'use-sound';
import checkoutSound from '../assets/mixkit-select-click-1109.wav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner, FaCheck, FaTimes } from 'react-icons/fa'; 

 

const Cart = () => {
    const [playCheckoutSound] = useSound(checkoutSound);
    const [loading, setLoading] = useState(true);
    const [tprice, setTprice] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState("")
    const allData = useContext(productContext);
    const url = allData.url;
    const address = allData.address;
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();

    const cartItems = useMemo(() => allData.cartData, [allData.cartData]);

    const totalPrice = useMemo(() => allData.totalPrice, [allData.totalPrice]);

     // Button state: 'idle' | 'loading' | 'success' | 'error'
     const [buttonState, setButtonState] = useState('idle');
    const removeProductFromCart = useCallback(async (id) => {
        try {
            setLoading(true);
            await axios.delete(`${url}/api/cart/remove/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': allData.authinticated
                }
            });
            await allData.fetchCartData();
            setLoading(false);
        } catch (error) {
            console.log(error.message);
            setLoading(false);
            toast.error('Failed to remove product from cart.', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    }, [allData, url]);

    const clearcart = useCallback(async () => {
        try {
            await axios.delete(`${url}/api/cart/clearCart`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Auth': allData.authinticated
                }
            });
            allData.fetchCartData();
        } catch (error) {
            console.log(error.message);
            toast.error('Failed to clear cart.', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    }, [allData, url]);

    const toDetails = useCallback((id) => {
        navigate(`/productDetails/${id}`);
    }, [navigate]);

    useEffect(() => {
        const cartData = async () => {
            await allData.fetchCartData();
            setLoading(false);
        };
        cartData();
    }, []);

    useEffect(() => {
        setTprice(totalPrice);
    }, [totalPrice]);

    const fetchProductById = useCallback(async (id, qty) => {
        try {
            const response = await axios.get(`${url}/api/product/${id}`);
            const quantityNow = response.data.product.Quantity - qty;
            const newQuantity = { ...response.data.product, Quantity: quantityNow };

            if (newQuantity) {
                try {
                    await axios.put(`${url}/api/product/${id}`, newQuantity);
                } catch (error) {
                    console.log(error.message);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [url]);

    const decreaseQuantity = useCallback(() => {
        cartItems.forEach(item => {
            fetchProductById(item.productId, item.qty);
        });
    }, [cartItems, fetchProductById]);

    const handlePayment = useCallback(async () => {
        if (address && cartItems.length !== 0) {
            try {
                setIsDisabled(true);
                setButtonState('loading');
                playCheckoutSound();
    
                const processingToastId = toast.info('Processing your order...', {
                    position: "top-right",
                    autoClose: false, 
                    closeOnClick: false,
                    draggable: false,
                });
    
                const orderResponse = await axios.post(`${url}/api/payment/checkout`, {
                    amount: tprice, cartItems, usershipping: address, userId
                });
    
                const { amount, orderId } = orderResponse.data;
    
                var options = {
                    "key": "rzp_live_UVshYgtn2fCscP",
                    "amount": amount * 100,
                    "currency": "INR",
                    "name": "Darsh Web",
                    "description": "Darsh Web",
                    "order_id": orderId,
                    "handler": async function (response) {
                        try {
                            const confirmOrder = {
                                orderId: response.razorpay_order_id,
                                paymentId: response.razorpay_payment_id,
                                signature: response.razorpay_signature,
                                amount,
                                orderItems: cartItems,
                                userId,
                                userShipping: address,
                            };
                            const api = await axios.post(`${url}/api/payment/verify`, confirmOrder);
                            if (api.data.success) {
                                toast.dismiss(processingToastId);
                                toast.success('Payment Successful! Redirecting...', {
                                    position: "top-right",
                                    autoClose: 3000,
                                });
                                decreaseQuantity();
                                clearcart();
                                setButtonState('success');
                                setTimeout(() => {
                                    navigate("/payment-confirmation");
                                }, 3000);
                            }
                        } catch (error) {
                            toast.dismiss(processingToastId);
                            toast.error('Payment Verification Failed!', {
                                position: "top-right",
                                autoClose: 3000,
                            });
                            console.log(error);
                            setButtonState('error');
                            setIsDisabled(false);
                        }
                    },
                    "prefill": {
                        "name": "Darsh",
                        "email": "Darshweb@gmail.com",
                        "contact": "9339268656"
                    },
                    "notes": {
                        "address": "Razorpay Corporate Office"
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                var rzp = new window.Razorpay(options);
                rzp.open();
            } catch (error) {
                toast.dismiss(); 
                toast.error('Checkout Failed! Please try again.', {
                    position: "top-right",
                    autoClose: 3000,
                });
                console.log(error);
                setIsDisabled(false); 
                setButtonState('error');
            }
        } else {
            setError("Please add address and product");
            toast.warn('Please add address and products to proceed.', {
                position: "top-right",
                autoClose: 3000,
            });
        }
    }, [address, cartItems, clearcart, decreaseQuantity, navigate, tprice, url, userId, playCheckoutSound]);
    
    return (
        <div>
            <ToastContainer />
            {loading ? (
                <div className={styles.loaderContainer}>
                    <HashLoader color={'#009578'} loading={loading} size={58} />
                </div>
            ) : (
                <div className={styles.outerDiv}>
                    <div className={styles.innerDiv}>
                        <div className={styles.leftDiv}>
                            {cartItems.map((elem) => {
                                const { productId, title, price, qty, imgSrc } = elem;

                                return (
                                    <div key={productId} className={styles.productDiv}>
                                        <img className={styles.productImg} src={imgSrc} alt="img" />
                                        <div className={styles.productDetails}>
                                            <div className={styles.productName}>
                                                <p>{title}</p>
                                            </div>
                                            <div className={styles.quantity}>
                                                <p className={styles.p}>Quantity: {qty}</p>
                                            </div>
                                            <div className={styles.quantity}>
                                                <p className={styles.p}>Price: {price}</p>
                                            </div>
                                        </div>
                                        <div className={styles.editAndRemove}>
                                            <div onClick={() => { toDetails(productId) }} className={styles.editBtn}>
                                                <p className={styles.editText}>Edit</p>
                                            </div>
                                            <div onClick={() => { removeProductFromCart(productId) }} className={styles.removetBtn}>
                                                <p className={styles.removeText}>Remove</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.rightDiv}>
                            <div className={styles.totalPriceDiv}>
                                <div className={styles.totalPriceDetails}>
                                    <p className={styles.priceDetails}>PRICE DETAILS</p>
                                </div>
                                <div className={styles.desPrice}>
                                    <div className={styles.price}>
                                        <p>Price:</p>
                                        <p>{tprice}</p>
                                    </div>
                                    <div className={styles.price}>
                                        <p>Delivery Charge:</p>
                                        <p>Free</p>
                                    </div>
                                    <div className={styles.price}>
                                        <p>Total Price:</p>
                                        <p>{tprice}</p>
                                    </div>
                                </div>
                                <div className={styles.checkOutDiv}>
                                <button
                                        onClick={handlePayment}
                                        disabled={isDisabled || buttonState === 'loading'}
                                        className={`${styles.checkOutBtn} ${buttonState === 'success' ? styles.success : ''} ${buttonState === 'error' ? styles.error : ''}`}>
                                        {buttonState === 'idle' && <p className={styles.checkOutText}>Place Order</p>}
                                        {buttonState === 'loading' && (
                                            <div className={styles.buttonContent}>
                                                <FaSpinner className={styles.spinner} />
                                                <p className={styles.checkOutText}>Processing...</p>
                                            </div>
                                        )}
                                        {buttonState === 'success' && (
                                            <div className={styles.buttonContent}>
                                                <FaCheck className={styles.iconSuccess} />
                                                <p className={styles.checkOutText}>Success!</p>
                                            </div>
                                        )}
                                        {buttonState === 'error' && (
                                            <div className={styles.buttonContent}>
                                                <FaTimes className={styles.iconError} />
                                                <p className={styles.checkOutText}>Error!</p>
                                            </div>
                                        )}
                                    </button>
                                </div>
                                {error && (
                                    <div className={styles.errorMsg}>
                                        {error}
                                    </div>
                                )}
                            </div>

                            <div className={styles.messageDiv}>
                                <p className={styles.messageText}>
                                    After the Delivery of the Product, the unboxing time must be recorded otherwise the return policy will not be approved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;
