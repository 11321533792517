import React, { useContext, useState, useCallback, useRef } from 'react';
import styles from "./Register.module.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer, Flip } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import productContext from '../Context/ProductContext/ProductContext';
import clickSound from '../assets/mixkit-select-click-1109.wav'; 

const Register = () => {
    const { url } = useContext(productContext);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const audioRef = useRef(null); 

    const handleChange = useCallback((e) => {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setPasswordError("");
        setLoading(true);

        audioRef.current.play();

        if (formData.password !== formData.confirmPassword) {
            setPasswordError("Passwords do not match");
            setLoading(false);
            return;
        }

        try {
            const { data } = await axios.post(`${url}/api/user/register`, {
                name: formData.name,
                email: formData.email,
                password: formData.password,
            });

            if (data.success) {
                toast.success(
                    <div className={styles.toastContent}>
                        Registration successful! Redirecting to login...
                    </div>, 
                    {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        transition: Flip, 
                        className: styles.successToast 
                    }
                );

                setTimeout(() => {
                    setLoading(false);
                    navigate('/login');
                }, 3000);
            } else {
                setError(data.message);
                setLoading(false);
            }
        } catch (err) {
            console.error('Registration error:', err);
            setError('Registration failed. Please try again.');
            setLoading(false);
        }
    }, [formData, url, navigate]);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <div className={styles.authContainer}>
            <ToastContainer />
            <form className={styles.authForm} onSubmit={handleSubmit}>
                <h2 className={styles.heading}>Sign Up</h2>

                <label className={styles.label} htmlFor="name">Name:</label>
                <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    required
                    className={styles.inputField}
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                />

                <label className={styles.label} htmlFor="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    className={styles.inputField}
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                />

                <label className={styles.label} htmlFor="password">Password:</label>
                <div className={styles.passwordWrapper}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter your password"
                        required
                        className={styles.inputField}
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        className={styles.togglePassword}
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                </div>

                <label className={styles.label} htmlFor="confirmPassword">Confirm Password:</label>
                <div className={styles.passwordWrapper}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        required
                        className={styles.inputField}
                        id="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        className={styles.togglePassword}
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                </div>

                {passwordError && (
                    <div className={styles.errorDiv}>
                        <p className={styles.error}>{passwordError}</p>
                    </div>
                )}

                <button
                    type="submit"
                    className={`${styles.submit} ${loading ? styles.disabled : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <div>
                            <span className={styles.res}>Registered ...</span>
                            <span className={styles.spinner}></span>
                        </div>
                    ) : 'SUBMIT'}
                </button>

                <p className={styles.instruction}>
                    Already a user? <span className={styles.link} onClick={() => navigate('/login')}>Log In</span>
                </p>

                {error && (
                    <div className={styles.errorDiv}>
                        <p className={styles.error}>{error}</p>
                    </div>
                )}
            </form>

            <audio ref={audioRef} src={clickSound} preload="auto" />
        </div>
    );
};

export default Register;
