import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./PaymentConfirmationPage.module.css";
import { FaCheckCircle } from 'react-icons/fa';

const PaymentConfirmationPage = () => {
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setAnimate(true); 
    }, []);

    const goToHome = () => {
        navigate("/");
    };

    const viewOrders = () => {
        navigate("/profile");
    };

    return (
        <div className={`${styles.outerDiv} ${animate ? styles.fadeIn : ''}`}>
            <div className={`${styles.confirmationCard} ${animate ? styles.slideIn : ''}`}>
                <FaCheckCircle className={`${styles.successIcon} ${animate ? styles.pulse : ''}`} />
                <h1 className={styles.title}>Payment Successful!</h1>
                <p className={styles.description}>Thank you for your purchase. Your order is confirmed.</p>

                <div className={styles.orderSummary}>
                </div>

                <div className={styles.buttonGroup}>
                    <button className={`${styles.homeButton} ${animate ? styles.buttonHover : ''}`} onClick={goToHome}>Go to Home</button>
                    <button className={`${styles.ordersButton} ${animate ? styles.buttonHover : ''}`} onClick={viewOrders}>View Orders</button>
                </div>
            </div>
        </div>
    );
};

export default PaymentConfirmationPage;
